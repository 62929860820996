import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'

import Layout from '~/components/Layout'
import Hero from '~/components/Hero'

const ContentWrapper = styled.div`
  padding: 100px;
  margin: 0 auto;
  max-width: 700px;
`

export default ({ data, location }) => {
  const siteMetadata = get(data, 'site.siteMetadata')
  const pages = get(data, 'allContentfulPage.edges')
    .map(({ node }) => node)

  const [metadata] = get(data, 'allContentfulMetadata.edges')

  const navPages = [...pages]
  navPages.push({ title: 'Contattaci', slug: 'contatti' })

  const title = `Pagina non trovata | ${siteMetadata.title}`

  return (
    <Layout
      location={location}
      title={title}
      url={siteMetadata.url}
      pages={navPages}
      metadata={metadata.node}
    >
      {/* message && <Modal message={message.node} /> */}
      <Hero location={location} data={metadata.node} />
      <ContentWrapper>
        <h1>Pagina non trovata</h1>
        <p>La pagina richiesta non è disponibile. Torna alla <a href="/">Homepage</a>.</p>
      </ContentWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ErrorQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
    allContentfulPage(sort: {fields: position}) {
      edges {
        node {
          title
          slug
          content {
            json
          }
          services {
            title
            slug
            heroImage {
              title
              fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
            body {
              json
            }
          }
          map {
            url
          }
        }
      }
    }
    allContentfulMetadata {
      edges {
        node {
          name
          body {
            json
          }
          text {
            text
          }
          keywords
          email
          phone
          cell
          fax
          address
          iva
          cf
          heroImage: image {
            title
            fluid(
              maxWidth: 1180,
              maxHeight: 480,
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
